import { graphql } from 'gatsby';
import MentoringNew from '../views/new-mentoring';

export default MentoringNew;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`